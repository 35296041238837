<template>
<div>
    <MenuDropZone class="drop-position" :parentCode="parentCode" :index="index" />
    <div v-bind="$attrs" class="menu-item" 
            :class="{dragged: menuItem.dragged, inactive: menuItem.status === 0}"
            draggable="true" 
            @dragstart.stop="startDrag($event, menuItem,parentCode,index)" 
            @dragend="stopDrag()" 
            >
        <div class="header">
            <div class="title">
                {{ menuItem.name }}
                <BaseIcon class="pen title_button"  @click="openModal(menuItem,'edit')" :title="$t('controls.edit_item')"/>
                <BaseIcon v-if="menuItem" class="link title_button"  @click="openModal(menuItem,'set_link')" :title="$t('cms.set_link')"/>
                <RouterLinkLocal v-if="menuItem.contentPageCode" :link="'url.project/:project_code/url.content_pages/!'+menuItem.contentPageCode" >
                    <BaseIcon class="go_to_page title_button" :title="$t('cms.go_to_page')"/>
                </RouterLinkLocal>
            </div>
            <BaseIcon class="plus_black" @click="openModal({parentCode: menuItem.code},'edit')" :title="$t('controls.add_new_item')" />
            <BaseIcon class="arrows" />
        </div>
        <MenuItemsBlock v-if="(menuItem.menu_items && menuItem.menu_items.length > 0)" :menuItems="menuItem.menu_items" :parentCode="menuItem.code"/>
        <MenuDropZone class="drop-item" v-if="!menuItem.dragged" :parentCode="menuItem.code" :index="menuItem.menu_items.length" />
        
    </div>
</div>
    
</template>

<script>
import useMenuDrag from '@admin/composable/MenuDrag';
import { defineAsyncComponent, inject } from 'vue';

export default {
    name: "MenuItem",
    components: {
        MenuItemsBlock: defineAsyncComponent(() => import(/* webpackChunkName: "menu-items-block" */'@admin/components/cms/menu/MenuItemsBlock.vue')),
        MenuDropZone: defineAsyncComponent(() => import(/* webpackChunkName: "menu-drop-zone" */'@admin/components/cms/menu/MenuDropZone.vue')),
    },
    props: {
        menuItem: Object,
        index: Number,
        parentCode: {
            type: String,
            default: null
        } 
    },
    setup(){
        const { startDrag,stopDrag } = useMenuDrag();

        const openModal = inject('openModal');
        
        return {
            startDrag,
            stopDrag,
            openModal
        }
    }
}
</script>

<style scoped>
.menu-item{
    position: relative;
    background: #ffbf48;
    border: 1px solid #177542;
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}
.menu-item.dragover{
    background: #da9b27;
}
.menu-item.inactive{
    filter: grayscale(80%);
}
.menu-item.dragged{
    opacity: 0.7;    
}
.menu-drop-zone.drop-item{
    position: absolute;
    /* width: 100%; */
    /* height: 100%; */
    height: 2.3rem;
    top: 0;
    left: 0;
    right: 2.5rem;
}
.header{
    display: flex;
    justify-content: space-between;
    height: 1.3rem;
}
.header .arrows{
    cursor: grab;
    margin: 0; 
}
.title_button{
    cursor: pointer;   
    margin: 0 0 0 0.5rem;
    display: none;
    width: 1.5rem;
}
.title_button.link{
    width: 1rem;
}
.header .plus_black{
    width: 1rem;  
    margin: 0 0.5rem;
    cursor: pointer; 
    display: none;
}
.title{
    display: flex;
    flex-grow: 1;
}
.menu-item:hover>.header>.title .title_button,.menu-item:hover>.header>.plus_black{
    display: block;
}
</style>